//import logo from './logo.svg'
import './App.css';
import ReactDOM from 'react-dom';
import { useEffect } from 'react';
import SignIn from './pages/signInPage';
import _ from './utils/firebase';
import MamberPage from './pages/memberPage';
import SideBarItem from './components/sidebarItem';
import PhetnakaCode from './pages/codePhetnakaPage';

function App() {

  useEffect(() => {

    _.auth.onAuthStateChanged(user => {
      if(user) {
          ReactDOM.render(<SideBarItem pages={<PhetnakaCode/>}/> , document.getElementById('root'));
          return
      } else {
          ReactDOM.render(<SignIn/> , document.getElementById('root'));
          return
      }

    });
  });

  return (
    <div className="App">
    </div>
  );
}

export default App;
