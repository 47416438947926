class StoneModel {

    key = null;
    id = null;
    properties = {
        _specialPercent:0
    }
    status = {

        _app:false,
        _normal:false,
        _special:false

    };
    path = {
        image:null
    }
}

class CreateRewardModel {

    name = null;
    status = false;
    percent = 0;
    image = null;
    description = null;
    properties = {
        type:null
    }
    timestamp = null;
}

class RewardIDModel {
    id = null;
}

class EditRewardModel {

    name = null;
    status = false;
    percent = 0;
    image = null;
    description = null;
    properties = {
        type:null
    }
    timestamp = null;
}

class RewardModel {

    key = null;
    name = null;
    status = false;
    percent = 0;
    image = null;
    description = null;
    properties = {
        type:null
    }

}

const _Stone = {

    StoneModel,
    CreateRewardModel,
    RewardIDModel,
    RewardModel,
    EditRewardModel

}

export default _Stone;
