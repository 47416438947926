import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom';
import _ from '../../../utils/firebase';
import _Stone from '../dto/stoneModel';
import ItemStone from '../cards/itemStone';
import SideBarItem from '../../sidebarItem';
import SettingPhetnakaPage from '../../../pages/settingPhetnakaPage';
import CreateFormReward from '../CRUD/Reward/RewardCreate';
import EditFormReward from '../CRUD/Reward/RewardEdit';
import DelFormReward from '../CRUD/Reward/RewardDelete';


const AllStone = (props) => {
    const id = props.data.key;
    const [item , setItem] = useState([]);

    const fetchDataGames = async () => {
        const snapshot = await _.p_db_reward.doc(id).collection('normal').get();
        let listItem = [];

        snapshot.forEach((doc) => {

            if(doc.data().status != undefined) {

                const id = doc.id;
                const data = doc.data();

                const model = new _Stone.RewardModel();
                model.key = id;
                model.name = data.name;
                model.image = data.image;
                model.percent = data.percent;
                model.description = data.description;
                model.status = data.status;
                model.properties = data.properties;
                
                listItem.push(model);
            }
            
        });

        setItem(listItem);
    }

    useEffect(() => {

        fetchDataGames();

    },[])

    const OpenFormCreateData = () => {

        var _form = document.getElementById('formCreateDataReward');
        _form.style.top = '0';

    }

    const BackToCompanyGame = () => {

        ReactDOM.render(<SideBarItem pages={<SettingPhetnakaPage/>}/> , document.getElementById('root'));

    }
    
    return(
        <>
            <CreateFormReward data={props}/>
            <EditFormReward data={props} />
            <DelFormReward data={props} />
            <div className='templete-table-code'>
                <div className='header-table'>
                    <div className='Group-Allgame'>
                        <button className='btn btncreate' onClick={BackToCompanyGame}>ย้อนกลับ</button>
                        <span className='text-header'><i className="fas fa-align-justify"></i> รางวัลทั้งหมด </span>
                    </div>
                    <button className='btn btncreate' onClick={OpenFormCreateData}>เพิ่มรางวัล</button>
                </div>

                <div>
                    <div className="list-container-card">
                        {item.map((d) => (
                            <ItemStone data={d}/>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )

}


export default AllStone;