import React, { useEffect, useState } from 'react';
import _ from '../../../../utils/firebase';
import '../../../../public/stylesheets/style-confirm.css';
import _Stone from '../../dto/stoneModel';

const DelFormReward = (props) => {

    const _data = {key:props.data.data.key , id:props.data.data.id}
    
    const delData = () => {
        
        const key = _Stone.RewardModel.prototype.key;
        _.p_db_reward.doc(_data.key).collection('normal').doc(key).delete();
        
        const query = document.querySelector(`[id=data${key}]`);
        query.remove();
        
        CloseFormDelData();

    }

    const CloseFormDelData = () => {

        var _form = document.getElementById('formDelDataReward');
        _form.style.top = '-100%';
        
    }

    
    return(

        <div className='formDelData' id='formDelDataReward'>
            <div className='box-data-search'>
                <div className='text-white'>
                    <h1 >ลบรางวัล</h1>
                </div>

                <div className='form-input'>
                    <span>รางวัล</span>
                    <input className='form-control' id={'r_delname'} type='text' readOnly></input>
                </div>
                <div className='form-input-btn'>
                    <div className='form-btn'>
                        <button className='btn btncreate' type='submit' onClick={delData}>ยืนยัน</button>
                        <button className='btn btncancel' onClick={CloseFormDelData}>ยกเลิก</button>
                    </div>                   
                </div> 

            </div>
        </div>
    )

}


export default DelFormReward;