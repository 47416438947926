import React, { useEffect, useState } from 'react';
import _ from "../../../utils/firebase";
import '../../../public/stylesheets/style-confirm.css';
import ReactDOM from 'react-dom';
import _PCode from '../dto/codeModel';
import SideBarItem from '../../sidebarItem';
import PhetnakaCode from '../../../pages/codePhetnakaPage';

function EditCodePhetnaka () {
    
    const [_codeNumber , setCodeNumber] = useState('');
    const [_description , setDescription] = useState('');

    const UpdateCode = async (e) => {

        e.preventDefault();

        const id = _PCode.P_CodeModel.prototype.codeNumber;
        let _modelCodeNumber = null;
        _codeNumber === undefined ? _modelCodeNumber = _PCode.P_CodeModel.prototype.codeNumber : _modelCodeNumber = _codeNumber;
        
        await _.p_db_code.doc(id).update({
            description:_description
        });

        CloseFormEditDataToRelode();
    }

    const CloseFormEditDataToRelode = () => {

        var _form = document.getElementById('formEditDataPhetnaka');
        _form.style.top = '-100%';

        refreshPage();

    }

    const CloseFormEditData = () => {

        var _form = document.getElementById('formEditDataPhetnaka');
        _form.style.top = '-100%';

    }

    const refreshPage = () => {

        setTimeout(() => {

            ReactDOM.render(<SideBarItem pages={''}/> , document.getElementById('root'));
            ReactDOM.render(<SideBarItem pages={<PhetnakaCode/>}/> , document.getElementById('root'));
            
        },500);

    }
    
    return(

        <div className='formEditData' id='formEditDataPhetnaka'>
            <div className='box-data'>

                <div className='text-white'>
                    <h1 >แก้ไขข้อมูลรหัสโค้ดเพชรนาคา</h1>
                </div>
                <div className='form-input'>
                    <span>ผู้ใช้งาน</span>
                    <input className='form-control' id={'_eduserID'} type='text' readOnly></input>
                </div>
                <div className='form-input'>
                    <span>รหัสโค้ด</span>
                    <input className='form-control' id={'_edcodeNumber'} type='text' readOnly></input>
                </div>
                <div className='form-input'>
                    <span>ระดับโค้ด</span>
                    <input className='form-control' id={'_edcodeType'} type='text'  readOnly></input>
                </div>
                 <div className='form-input'>
                    <span>จำนวนสิทธิ์</span>
                    <input className='form-control' id={'_edamount'} type='text'  readOnly></input>
                   
                </div>
                <div className='form-input'>
                        <span>หมายเหตุ</span>
                        <input className='form-control' id={'_edescription'} type='text' onChange={(e) => setDescription(e.target.value)}></input>
                </div>
                <div className='form-input-btn'>
                    <div className='form-btn'>
                        <button className='btn btncreate' type='submit' onClick={UpdateCode}>ยืนยัน</button>
                        <button className='btn btncancel' onClick={CloseFormEditData}>ยกเลิก</button>
                    </div>                   
                </div> 
            </div>
        </div>
    )

}


export default EditCodePhetnaka;