import React, { useEffect, useState } from 'react';
import _ from "../../../utils/firebase";
import '../../../public/stylesheets/style-confirm.css';
import _PCode from '../dto/codeModel';

function DelCodePhetnaka () {
    
    const delData = async () => {

        const id = _PCode.P_CodeModel.prototype.codeNumber;
        const query = document.querySelector(`[id=data${id}]`);
        query.remove();

        await _.p_db_code.doc(id).delete();

        CloseFormDelData();

    }

    const CloseFormDelData = () => {

        var _form = document.getElementById('formDelDataPhetnaka');
        _form.style.top = '-100%';
        
    }

    return(

        <div className='formDelData' id='formDelDataPhetnaka'>
            <div className='box-data'>

                <div className='text-white'>
                    <h1 >ลบรหัสโค้ดเพชรนาคา</h1>
                </div>
                <div className='form-input'>
                    <span>ผู้ใช้งาน</span>
                    <input className='form-control' id={'_deluserID'} type='text' ></input>
                </div>
                <div className='form-input'>
                    <span>รหัสโค้ด</span>
                    <input className='form-control' id={'_delcodeNumber'} type='text' readOnly></input>
                </div>
                <div className='form-input'>
                    <span>ระดับโค้ด</span>
                    <input className='form-control' id={'_delcodeType'} type='text'  readOnly></input>
                </div>
                <div className='form-input-btn'>
                    <div className='form-btn'>
                        <button className='btn btncreate' type='submit' onClick={delData}>ยืนยัน</button>
                        <button className='btn btncancel' onClick={CloseFormDelData}>ยกเลิก</button>
                    </div>                   
                </div> 

            </div>
        </div>
    )

}


export default DelCodePhetnaka;