/* eslint-disable jsx-a11y/scope */

import _ from "../../../utils/firebase";
import '../../../public/stylesheets/style-card.css';
import { useEffect, useState } from "react";
import _Stone from "../dto/stoneModel";

const ItemStone = (props) => {

    const model = new _Stone.RewardModel();
    model.key = props.data.key;
    model.name = props.data.name;
    model.image = props.data.image;
    model.description = props.data.description;
    model.percent = props.data.percent;
    model.status = props.data.status;
    model.properties = props.data.properties;

    const updateData = async () => {

        

        _Stone.RewardModel.prototype.key = props.data.key;
        _Stone.RewardModel.prototype.name = props.data.name;
        _Stone.RewardModel.prototype.image = props.data.image;
        _Stone.RewardModel.prototype.description = props.data.description;
        _Stone.RewardModel.prototype.percent = props.data.percent;
        _Stone.RewardModel.prototype.status = props.data.status;
        _Stone.RewardModel.prototype.properties = props.data.properties;

        var _form = document.getElementById('formEditDataReward');
        _form.style.top = '0';
       
        var _name = document.getElementById('r_edname'),
            _type = document.getElementById('r_edtype'),
            _percent = document.getElementById('r_edpercent'),
            _description = document.getElementById('r_eddescription'),
            _status = document.getElementById('r_edstatus');

        _name.value = model.name;
        _type.value = model.properties.type;
        _percent.value = model.percent;
        _description.value = model.description;

        model.status ? _status.value = true : _status.value = false;

    }

    const delData = async () => {

        _Stone.RewardModel.prototype.key = props.data.key;

        var _form = document.getElementById('formDelDataReward');
        _form.style.top = '0';

        var _name = document.getElementById('r_delname');

        _name.value = model.name;


    }


    return (

        <div className="listCard-game" id={`data${model.key}`}>
            <div className="card">
                <img src={model.image} alt={model.name} width={140} height={140}></img>
                <div className="container-card">
                    <span className="sp-card-games">{model.name}</span>
                    <span className="sp-card-games">{`ประเภทรางวัล : ${model.properties.type}`}</span>
                    <span className="sp-card-games">{`เปอร์เซ็นต์ออกรางวัล ${model.percent}%`}</span>
                    <span className={model.status ? "sp-card-games text-status-on" : "sp-card-games text-status-off"}>
                        {model.status ? 'สถานะ เปิดใช้งาน' : 'สถานะ ปิดใช้งาน'}
                    </span>
                    <span className="sp-card-games">
                        <button className="btn text-white btnedit" id={`edit${model.key}`} onClick={updateData}>แก้ไขข้อมูล</button>
                        <button className="btn text-white btndel" id={`del${model.key}`} onClick={delData}>ลบข้อมูล</button>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default ItemStone;