/* eslint-disable jsx-a11y/scope */

import _ from "../../../utils/firebase";
import '../../../public/stylesheets/style-card.css';
import ReactDom from 'react-dom';
import _Stone from "../dto/stoneModel";
import SideBarItem from "../../sidebarItem";
import AllStone from "../stone/viewAllStone";

const ItemCard = (props) => {

    const _data = {key:props.data.key , id:props.data.id}

    const updateData = async () => {
        _Stone.StoneModel.prototype.key = props.data.key;
        _Stone.StoneModel.prototype.id = props.data.id;
        _Stone.StoneModel.prototype.status = props.data.status;
        _Stone.StoneModel.prototype.properties = props.data.properties;
        _Stone.StoneModel.prototype.path = props.data.path;

        var _form = document.getElementById('formEditDataStone');
        _form.style.top = '0';
       
        var _status = document.getElementById('_edstatus-c')
        props.data.status._normal ? _status.value = 'true' : _status.value = 'false';

    }

    const OpenFormCreateData = () => {
        ReactDom.render(<SideBarItem pages={<AllStone data={_data}/>}/> , document.getElementById('root'));

    }


    return (
        <>
            <div className="listCard" id={`data${props.data.id}`}>
                <div className="card">
                    <img src={props.data.path.image} alt={props.data.id}></img>
                    <div className="container-card">
                        <span className="sp-card">{props.data.id}</span>
                        <span className={ props.data.status._normal ? "sp-card text-status-on" : "sp-card text-status-off"}>{props.data.status._normal ? 'สถานะ เปิดใช้งาน' : 'สถานะ ปิดใช้งาน'}</span>
                        <span className="sp-card">
                            <button className="btn text-white btn-primary" id={`edit${props.data.id}`} onClick={(e) => OpenFormCreateData(e)}>ดูรางวัล</button>
                            <button className="btn text-white btnedit" id={`edit${props.data.id}`} onClick={(e) => updateData(e)}>แก้ไขข้อมูล</button>
                        </span>
                    </div>
                </div>
            </div>
        </>

    )
}

export default ItemCard;