import React, { useEffect, useState } from 'react'
import _ from '../utils/firebase'
import '../public/stylesheets/style-login.css'
//import ReactDOM from 'react-dom'


const SignIn = () => {


    const [email , setEmail] = useState('')
    const [password , setPassword] = useState('')

    const FirebaseAuthenLoginWithEmail = (e) => {
        e.preventDefault()
        
        if(email === '' && password === '' ||
            email === '' && password !== '' ||
            email !== '' && password === '') {

            window.alert(`โปรดกรอกรหัสให้ครบถ้วน`);

        } else {

            _.auth.signInWithEmailAndPassword(email , password)
            .then((user) => {
                console.log(user);
                //ReactDOM.render(<MainPage pages={<SerialCode/>}/> , document.getElementById('root'))
            })
            .catch((err) => {
                window.alert(`กรอกรหัสไม่ถูกต้อง`)
            })
        }
    }

    return(

        <div className='LoginForm'> 
            <form className='form-SignIn'>
                <div className='Login-Container box-data-login'>
                    <div className='text-header-login'>
                        <h4>ล็อคอินเข้าสู่ระบบ</h4>
                        <h1>ล่าไข่มังกร</h1>
                    </div>
                    <div className='input-gp'>
                        <div className='form-input-login'>
                            <div className='input-form text-white'>
                                <span>E-mail</span>
                                <input className='form-cotrol-login input-SignIn ' id='_uid' type='text' placeholder='exsample@gmail.com' value={email} onChange={(e) => setEmail(e.target.value)}></input>
                            </div>
                        </div>
                        <br></br>
                        <div className='form-input-login'>
                            <div className='input-form text-white'>
                                <input className='form-cotrol-login input-SignIn' id='_upassword' type='password' placeholder='password' value={password} onChange={(e) => setPassword(e.target.value)}></input>
                            </div>
                        </div>
                    </div>
                    <div className='form-input-login login-descripts'>
                        <h5>เข้าสู่ระบบเพิ่อการจัดการข้อมูลภายในของลูกค้า</h5>
                    </div>          
                    <div className='form-input-login-btn'>
                        <div className='form-btn'>
                            <button className='btn btncreate btn-Login' type='submit' onClick={FirebaseAuthenLoginWithEmail}>เข้าสู่ระบบ</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>

    )
}

export default SignIn