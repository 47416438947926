import React, { useEffect, useState } from 'react'
import _ from '../utils/firebase';
import ItemCard from '../components/phetnaka/cards/itemCard';
import EditCodeStone from '../components/phetnaka/stone/editStone';
import _Stone from '../components/phetnaka/dto/stoneModel';

function SettingPhetnakaPage () {

    const [item , setItem] = useState([]);
    async function fetchData () {

        const snapshot = await _.p_db_reward.get();
        let listItem = [];

        snapshot.forEach((doc) => {

            const data = doc.data();

            const model = new _Stone.StoneModel();
            model.key = doc.id;
            model.id = data.id;
            model.properties = data.properties;
            model.status = data.status;
            model.path = data.path;
            listItem.push(model);
        });

        setItem(listItem);

    }

    useEffect(() => {

        fetchData();

    },[])

    const OpenFormCreateDataCompany = () => {

        var _form = document.getElementById('formCreateDataStone');
        _form.style.top = '0';
    }

    return(

        <>
            <EditCodeStone/>
            <div className='templete-table-code'>
                <div className='header-table'>
                    {/* <div className='group-btn'>
                        <button className='btn btncreate btn-games' onClick={OpenFormCreateDataCompany}>เพิ่มหินนาคา</button>
                    </div> */}
                </div>

                <div className="list-container-card">
                        {item.map((d) => (
                            <ItemCard data={d} />
                        ))}
                </div>
            </div>
        </>
    )

}


export default SettingPhetnakaPage;