import React, { useEffect, useState } from 'react';
import _ from '../../../../utils/firebase';
import '../../../../public/stylesheets/style-confirm.css';
import ReactDOM from 'react-dom';
import _Stone from '../../dto/stoneModel';
import SideBarItem from '../../../sidebarItem';
import AllStone from '../../stone/viewAllStone';

const EditFormReward = (props) => {

    

    const _data = {key:props.data.data.key , id:props.data.data.id}

    const [_img , setImg] = useState();
    const [_name , setName] = useState();
    const [_description , setDescription] = useState();
    const [_percent ,setPercent] = useState();
    const [_status , setStatus] = useState();
    const [_type , setType] = useState();

    const pathImage = `page/static/images/phetnaka/stone/${_data.id}/rewards/normal/${_name}`;


    const uploadFile = () => {

        _.db_storage.ref(pathImage).put(_img).then(()=>{
            loadFile();
        }).catch(err => {
            console.log(`${err.message}`);
        })

    }

    const loadFile = () => {
        _.db_storage.ref(pathImage).getDownloadURL().then(_url=>{
            const path = _url;
            UpdateNowReward(path);
        }).catch(err => {

            console.log(`${err.message}`);
        })

    }

    const UpdateNowReward = (url) => {

        var _onstatus = null;
        _status === undefined ? _onstatus = _Stone.RewardModel.prototype.status : _onstatus = _status;

        const model = new _Stone.EditRewardModel();
        _name != undefined ? model.name = _name : model.name = _Stone.RewardModel.prototype.name;
        _type != undefined ? model.properties.type = _type : model.properties.type = _Stone.RewardModel.prototype.properties.type;
        _description != undefined ? model.description = _description : model.description = _Stone.RewardModel.prototype.description;
        _percent != undefined ? model.percent = _percent : model.percent = _Stone.RewardModel.prototype.percent;
        model.status = _onstatus;
        model.image = url;

        _.p_db_reward.doc(_data.key).collection('normal').doc(_Stone.RewardModel.prototype.key).update({...model});

        CloseFormEditDataToRelode();

    }

    const UpdateReward = async (e) => {

        e.preventDefault();

        if(_img === undefined) {

            UpdateNowReward(_Stone.RewardModel.prototype.image);

        } else {

            uploadFile();
        }

    }

    const CloseFormEditDataToRelode = () => {

        var _form = document.getElementById('formEditDataReward');
        _form.style.top = '-100%';

        refreshPage();

    }

    const CloseFormEditData = () => {

        var _form = document.getElementById('formEditDataReward');
        _form.style.top = '-100%';

    }

    const refreshPage = () => {

        setTimeout(() => {

            ReactDOM.render(<SideBarItem pages={''}/> , document.getElementById('root'));
            ReactDOM.render(<SideBarItem pages={<AllStone data={_data}/>}/> , document.getElementById('root'));
            
        },500);

    }
    
    return(

        <div className='formEditData' id='formEditDataReward'>
            <div className='box-data'>

                <div className='text-white'>
                    <h1 >แก้ไขข้อมูลรางวัล</h1>
                </div>

                <div className='form-input'>
                    <span>ชื่อรางวัล</span>
                    <input className='form-control' id={'r_edname'} type='text' value={_name} onChange={(e) => setName(e.target.value)} required></input>
                </div>
                <div className='form-input'>
                        <span>ประเภทรางวัล</span>
                        <input className='form-control' id={'r_edtype'} type='text' value={_type} onChange={(e) => setType(e.target.value)} placeholder='ประเภทรางวัล' required></input>
                    </div>
                 <div className='form-input'>
                    <span>สถานะ</span>
                    <select className='form-control'  id={'r_edstatus'} value={_status}  onChange={(e) => setStatus(e.target.value)} >
                        <option value={true} >เปิดใช้งาน</option>
                        <option value={false}>ปิดใช้งาน</option>
                    </select>
                </div>
                <div className='form-input'>
                        <span>เปอร์เซ็นต์ออกรางวัล</span>
                        <input className='form-control' id={'r_edpercent'} type='number' value={_percent} onChange={(e) => setPercent(e.target.value)} placeholder='เปอร์เซ็นต์การออกรางวัล' required></input>
                    </div>
                    <div className='form-input'>
                        <span>รายละเอียด</span>
                        <input className='form-control' id={'r_eddescription'} type='text' value={_description} onChange={(e) => setDescription(e.target.value)} placeholder='รายละเอียดเพิ่มเติม'></input>
                    </div>
                <div className='form-input'>
                    <span>อัพโหลดรูปภาพ</span>
                    <input className='form-control input-file' id={'r_edfiles'} type='file' onChange={(e) => setImg(e.target.files[0])}></input>
                   
                </div>
                <div className='form-input-btn'>
                        <div className='form-btn'>
                            <button className='btn btncreate' type='submit' onClick={UpdateReward}>ยืนยัน</button>
                            <button className='btn btncancel' onClick={CloseFormEditData}>ยกเลิก</button>
                        </div>                   
                </div> 

            </div>
        </div>
    )

}


export default EditFormReward;