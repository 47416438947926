import React, { useEffect, useState } from 'react';
import '../../../../public/stylesheets/style-confirm.css';
import ReactDOM from 'react-dom';
import SideBarItem from '../../../sidebarItem';
import AllStone from '../../stone/viewAllStone';
import _ from '../../../../utils/firebase';
import _Stone from '../../dto/stoneModel';


const CreateFormReward = (props) => {

    const _data = {key:props.data.data.key , id:props.data.data.id}

    const [_img , setImg] = useState('');
    const [_name , setName] = useState('');
    const [_description , setDescription] = useState('');
    const [_percent ,setPercent] = useState('');
    const [_status , setStatus] = useState('');
    const [_type , setType] = useState('');

    const [_itemCompany , setItemCompany] = useState([]);
    const [following , setFollowing] = useState(true);


    const pathImage = `page/static/images/phetnaka/stone/${_data.id}/rewards/normal/${_name}`;

    const fachdata = async () => {

        try {

            setFollowing(false);

            const snapshot = await _.p_db_reward.doc(_data.key).get();
            let listItem = [];

            snapshot.forEach((doc) => {

                const data = doc.data();

                listItem.push({data});

            });

        setItemCompany(listItem);

        } catch(e) {
            console.log(e.message);
        }

    }


    useEffect(() => {

        if(following) fachdata();

    })


    const formData = async (e) => {


        e.preventDefault();
        uploadFile();

    }

    const uploadFile = () => {

        _.db_storage.ref(pathImage).put(_img).then(()=>{
            loadFile();
        }).catch(err => {
            console.log(`${err.message}`);
        })

    }

    const loadFile = () => {
        _.db_storage.ref(pathImage).getDownloadURL().then(_url=>{
            const path = _url;
            AddNewGames(path);
        }).catch(err => {

            console.log(`${err.message}`);
        })

    }

    const AddNewGames = (_url) => {

        var _onstatus = null;
        _status === 'true' ? _onstatus = true : _onstatus = false;

        const model = new _Stone.CreateRewardModel();
        model.name = _name;
        model.properties.type = _type;
        model.percent = _percent;
        model.description = _description;
        model.status = _onstatus;
        model.image = _url;
        model.timestamp = _.db_time;

        _.p_db_reward.doc(_data.key).collection('normal').add({...model});

        CloseFormCreateData();

    }

    const CloseFormCreateDef = (e) => {

        e.preventDefault();

        var _form = document.getElementById('formCreateDataReward');
        _form.style.top = '-100%';

        // var _input_number = document.getElementById('_number'),
        //     _input_member = document.getElementById('_member'),
        //     _input_amount = document.getElementById('_amount');

        // _input_amount.value = null;
        // _input_number.value = null;
        // _input_member.value = null;

    }

    const CloseFormCreateData = () => {

        var _form = document.getElementById('formCreateDataReward');
        _form.style.top = '-100%';

        refreshPage();
    }

    const refreshPage = () => {

        setTimeout(() => {
            
            ReactDOM.render(<SideBarItem pages={''}/> , document.getElementById('root'));
            ReactDOM.render(<SideBarItem pages={<AllStone data={_data}/>}/> , document.getElementById('root'));
            
        },500);

    }

    return(

        <form>

            <div className='formCreateData' id='formCreateDataReward'>

                <div className='box-data'>
                    <div className='text-white'>
                        <h1 >เพิ่มรางวัล</h1>
                    </div>
                    <div className='form-input'>
                        <span>ชื่อรางวัล</span>
                        <input className='form-control' id={'r_name'} type='text' value={_name} onChange={(e) => setName(e.target.value)} placeholder='ชื่อรางวัล'></input>
                    </div>
                    <div className='form-input'>
                        <span>ประเภทรางวัล</span>
                        <input className='form-control' id={'r_type'} type='text' value={_type} onChange={(e) => setType(e.target.value)} placeholder='ประเภทรางวัล'></input>
                    </div>
                    <div className='form-input'>
                        <span>สถานะ</span>
                        <select className='form-control' id={'r_status'} value={_status} onChange={(e) => setStatus(e.target.value)}>
                            <option value={'default'}>เลือกสถานะ</option>
                            <option value={'true'}>เปิดใช้งาน</option>
                            <option value={'false'}>ปิดใช้งาน</option>
                        </select>
                    </div>
                    <div className='form-input'>
                        <span>เปอร์เซ็นต์ออกรางวัล</span>
                        <input className='form-control' id={'r_percent'} type='number' value={_percent} onChange={(e) => setPercent(e.target.value)} placeholder='เปอร์เซ็นต์การออกรางวัล'></input>
                    </div>
                    <div className='form-input'>
                        <span>รายละเอียด</span>
                        <input className='form-control' id={'r_description'} type='text' value={_description} onChange={(e) => setDescription(e.target.value)} placeholder='รายละเอียดเพิ่มเติม'></input>
                    </div>
                    <div className='form-input'>
                        <span>ไฟล์</span>
                        <input className='form-control input-file' id={'file'} type='file' onChange={(e) => setImg(e.target.files[0])} ></input>
                    </div>
                    <div className='form-input-btn'>

                            <div className='form-btn'>
                                <button className='btn btncreate' type='submit' onClick={formData} >ยืนยัน</button>
                                <button className='btn btncancel' onClick={CloseFormCreateDef}>ยกเลิก</button>
                            </div>                   
                    </div>
                </div>
            </div>
        </form>
    )

}


export default CreateFormReward;