/* eslint-disable jsx-a11y/scope */

import '../../public/stylesheets/style-serial.css';
import _PCode from "./dto/codeModel";

const ItemObj = (props) => {

    const updateData = async () => {
        
        _PCode.P_CodeModel.prototype.id = props.id;
        _PCode.P_CodeModel.prototype.codeNumber = props.codeNumber;
        _PCode.P_CodeModel.prototype.description = props.description;

        var _form = document.getElementById('formEditDataPhetnaka');
        _form.style.top = '0';
       
        var _code = document.getElementById('_edcodeNumber'),
            _codeType = document.getElementById('_edcodeType'),
            _amount = document.getElementById('_edamount'),
            _userID = document.getElementById('_eduserID'),
            _description = document.getElementById('_edescription');

        _code.value = props.codeNumber;
        _codeType.value = props.codeType;
        props.description === null ? _description.value = '-' : _description.value = props.description;
        props.amount === null ? _amount.value  = '-' :_amount.value = props.amount;
        props.userID === '' ? _userID.value = 'ไม่พบรหัส' : _userID.value = `${props.userID}`;
    }

    const delData = async () => {

        _PCode.P_CodeModel.prototype.id = props.id;
        _PCode.P_CodeModel.prototype.codeNumber = props.codeNumber;

        var _form = document.getElementById('formDelDataPhetnaka');
        _form.style.top = '0';

        var _codeNumber = document.getElementById('_delcodeNumber'),
            _codeType = document.getElementById('_delcodeType'),
            _userID = document.getElementById('_deluserID');

        _codeNumber.value = props.codeNumber;
        _codeType.value = props.codeType;
        props.userID === '' ? _userID.value = '-' : _userID.value = `${props.userID}`;

    }

    return (

        <tr id={`data${props.id}`} key={props.id}>
            <td scope="col">{`${(new Date(props.timestamp.seconds*1000).toLocaleString())}`}</td>
            <td scope="col">{props.userID === null || props.userID === '' ? 'ไม่พบรหัส' : `${props.userID}`}</td>
            <td scope="col">{props.codeNumber}</td>
            <td scope="col">{props.codeType}</td>
            <td scope="col">{props.amount}</td>
            <td scope="col">{props.description === null ? '-' : props.description}</td>
            <td>
                <div>
                    <button className="btn text-white btnedit" id={`edit${props.id}`} onClick={updateData}>แก้ไขข้อมูล</button>
                    <button className="btn text-white btndel" id={`del${props.id}`} onClick={delData}>ลบข้อมูล</button>
                </div>
            </td>
        </tr>
    )
}

export default ItemObj;