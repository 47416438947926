import React, { useEffect, useState } from 'react'
import _ from '../utils/firebase';
import _PCode from '../components/phetnaka/dto/codeModel';
import ItemObj from '../components/phetnaka/itemCode';
import CreateCodePhetnaka from '../components/phetnaka/CRUD/itemCreate';
import EditCodePhetnaka from '../components/phetnaka/CRUD/itemEdit';
import DelCodePhetnaka from '../components/phetnaka/CRUD/itemDel';

function PhetnakaCode () {

    const [item , setItem] = useState([]);
    const [_isReq , setReq] = useState(true);
    async function fetchData () {

        setReq(false);
        const snapshot = await _.p_db_code.orderBy('timestamp' , 'desc').get();
        let listItem = [];

        snapshot.forEach((doc) => {

            // if(doc.data().expire != null) {

            //     const today = new Date();
            //     const userDate = new Date(doc.data().expire);
            //     // if(today > userDate) {
            //     //     DeleteUserCode(doc.id);
            //     // }

            // } else {

            //     const today = new Date();
            //     const userCreate = new Date(doc.data().create);
            //     today.setDate(today.getDate() - 2);

            //     // if(today > userCreate) {

            //     //     console.log(doc.id);
            //     // }

            // }

            const id = doc.id;
            const data = doc.data();

            listItem.push({id,...data});
        });

        setItem(listItem);

    }

    const OnmodelCode = (data) => {
        
        const model = new _PCode.P_CodeModel();
        model.id = data.id
        model.userID = data.userID;
        model.prefix = data.prefix;
        model.codeNumber = data.codeNumber;
        model.codeType = data.codeType;
        model.amount = data.amount;
        model.description = data.description;
        model.status = data.status;
        model.timestamp = data.timestamp;
       
        
        return model;
    }

    const OpenFormCreateData = () => {

        var _form = document.getElementById('formCreateDataPhetnaka');
        _form.style.top = '0';

    }

    useEffect(() => {

        if(_isReq) fetchData();

    },[])

    return(
        <>
            <CreateCodePhetnaka/>
            <EditCodePhetnaka/>
            <DelCodePhetnaka/>
            <div className='templete-table-code'>
                <div className='header-table'>
                    <span className='text-header'><i className="fas fa-align-justify"></i> รายการโค้ดทั้งหมดเพชรนาคา </span>
                    <button className='btn btncreate' onClick={OpenFormCreateData}>สร้างรหัสโค้ด</button>
                </div>

                <div className="table-data">
                    <table className="table" id='show-data'>
                        <thead>
                            <tr>
                                <th scope="col"> ปี/เดือน/วัน - เวลา</th>
                                <th scope="col"> ยูสเซอร์</th>
                                <th scope="col"> รหัสโค้ด</th>
                                <th scope="col"> ระดับโค้ด</th>
                                <th scope="col"> จำนวนสิทธิ์</th>
                                <th scope="col"> หมายเหตุ</th>
                                <th scope="col"> จัดการ</th>
                            </tr>
                        </thead>
                        <tbody id="serialList">
                            {item.map((d) => (
                                <ItemObj {...OnmodelCode(d)}/>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>

    )

}


export default PhetnakaCode;