import React, { useEffect, useState } from "react";
import _ from "../utils/firebase";
import '../public/stylesheets/style-member.css';
import '../public/stylesheets/style-confirm.css';
import AddMember from "../components/form/created/memberCreated";
import { Button, ButtonBase, IconButton, Pagination, Paper, Stack ,Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { TableView } from "@mui/icons-material";


const setWheel = () => {
    console.log('set');

    var amount = {max:0 , min:0}
    // var image = {reward:"null"}

    // for(var i=0; i< 10; i++) {

    //     _.db_reward.doc('bijVChbtuH2i6Jly7I7m').collection('egg_Data').add({
    //         amount:amount,
    //         description:"null",
    //         percent:0,
    //         type:"freeOnly"
    //     })
    // }

    // var properties = {secretFeature : {
    //                     opensupport:"sellOnly",
    //                     percent:100,
    //                     status:true,
    //                     slotPoint: [
    //                         {slot:{ amount : 2,
    //                                 amountLimit : 2 ,
    //                                 dateType : "day" ,
    //                                 number : 1 ,
    //                                 percent : 50 ,
    //                                 point : 1}
    //                         },
    //                         {slot:{ amount : 2,
    //                             amountLimit : 2 ,
    //                             dateType : "day" ,
    //                             number : 2 ,
    //                             percent : 50 ,
    //                             point : 1}
    //                         },
    //                         {slot:{ amount : 2,
    //                             amountLimit : 2 ,
    //                             dateType : "day" ,
    //                             number : 3 ,
    //                             percent : 50 ,
    //                             point : 1}
    //                         },
    //                         {slot:{ amount : 2,
    //                             amountLimit : 2 ,
    //                             dateType : "day" ,
    //                             number : 4 ,
    //                             percent : 50 ,
    //                             point : 1}
    //                         },
    //                         {slot:{ amount : 2,
    //                             amountLimit : 2 ,
    //                             dateType : "day" ,
    //                             number : 5 ,
    //                             percent : 50 ,
    //                             point : 1}
    //                         },
    //                         {slot:{ amount : 2,
    //                             amountLimit : 2 ,
    //                             dateType : "day" ,
    //                             number : 6 ,
    //                             percent : 50 ,
    //                             point : 1}
    //                         },
    //                         {slot:{ amount : 2,
    //                             amountLimit : 2 ,
    //                             dateType : "day" ,
    //                             number : 7 ,
    //                             percent : 50 ,
    //                             point : 1}
    //                         },
    //                         {slot:{ amount : 2,
    //                             amountLimit : 2 ,
    //                             dateType : "day" ,
    //                             number : 8 ,
    //                             percent : 50 ,
    //                             point : 1}
    //                         },
    //                         {slot:{ amount : 2,
    //                             amountLimit : 2 ,
    //                             dateType : "day" ,
    //                             number : 0 ,
    //                             percent : 50 ,
    //                             point : 1}
    //                         }
    //                     ]
    // }}

    // var doc = [
    //     {silver: {
    //         coin:"ไข่ Silver โอกาสในการได้รับ \n Coin 1 - 100" , 
    //         fullSize: "https://storage.googleapis.com/dracovalley24.appspot.com/page/static/images/eggs/original/sliveregg_fullSize.png" ,
    //         miniSize : "https://storage.googleapis.com/dracovalley24.appspot.com/page/static/images/eggs/original/sliveregg_miniSize.png"
    //         }
    //     },
    //     {gold: {
    //         coin:"ไข่ Gold โอกาสในการได้รับ \n  Coin 1 - 1,000",
    //         fullSize: "https://storage.googleapis.com/dracovalley24.appspot.com/page/static/images/eggs/original/goldegg_fullSize.png" ,
    //         miniSize : "https://storage.googleapis.com/dracovalley24.appspot.com/page/static/images/eggs/original/goldegg_miniSize.png" 
    //         }
    //     },
    //     {platinum: {
    //         coin:"ไข่ Platinum โอกาสในการได้รับ \n Coin 1 - 10,000" , 
    //         fullSize: "https://storage.googleapis.com/dracovalley24.appspot.com/page/static/images/eggs/original/platinumegg_fullSize.png" ,
    //         miniSize : "https://storage.googleapis.com/dracovalley24.appspot.com/page/static/images/eggs/original/platinumegg_miniSize.png" 
    //         }
    //     },
    //     {diamond: {
    //         coin:"ไข่ Diamond โอกาสในการได้รับ \n Coin 1 - 100,000" , 
    //         fullSize: "https://storage.googleapis.com/dracovalley24.appspot.com/page/static/images/eggs/original/diamondegg_fullSize.png" ,
    //         miniSize : "https://storage.googleapis.com/dracovalley24.appspot.com/page/static/images/eggs/original/diamondegg_miniSize.png"
    //         }
    //     },
    // ]

    // _.db_eggs.doc("eggsReward").set({
    //     doc:doc
    // })
}

//setWheel()

const MamberPage = () => {

    const [itemMember , setItemMember] = useState([]);
    const [lastDoc , setLastDoc] = useState();

    const [id , setID] = useState('');
    const [member , setMember] = useState('');
    const [description , setDescription] = useState('');

    useEffect(() => {

        const memberRef = _.db_member;
        const unsub = memberRef.orderBy('objcreate' , 'desc').limit(30).onSnapshot((snapshot) => {
            // console.log(snapshot.docs.length);
            updateState(snapshot);
        },(err) => {
            console.log(err);
        })

        return () => {
            unsub();
        };

    },[]);

    

    const updateState = (collections) => {
        const memberArray = collections.docs.map((member) => member);
        const lastDoc = collections.docs[collections.docs.length - 1];
        setItemMember(itemMember => [...memberArray]);
        setLastDoc(lastDoc);
    }

    const updateMore = (collections) => {
        const codeArray = collections.docs.map((member) => member);
        const lastDoc = collections.docs[collections.docs.length - 1];
        setItemMember((itemMember) => [...itemMember, ...codeArray]);
        setLastDoc(lastDoc);
    }

    const fetchMore =() => {

        const memberRef = _.db_member;
        memberRef.orderBy('objcreate' , 'desc').startAfter(lastDoc).limit(30).get().then((collections) => {
            updateMore(collections);
        },(err) => {
            console.log(err);
        })
    }

    // if(itemMember.length === 0) {
    //     return <h1>ไม่พบข้อมูล</h1>
    // }

//#region  Add Member

const addHandle = () => {

    var _form = document.getElementById('form-member-create-data');
     _form.style.top = '0';
 }

//#endregion 
    
//#region  Editing Member
    const editHandle = (id , obj) => {
        setID(id);
        setMember(obj.name);
        setDescription(obj.description);

        var _form = document.getElementById('form-member-edit-data');
        _form.style.top = '0';

    }

    const SendEditMember = (e) => {

        e.preventDefault();
        
        _.db_member.doc(id).update({
            name:member,
            description:description,
        })

        var _form = document.getElementById('form-member-edit-data');
        _form.style.top = '-100%';
    }

    const CloseEditMember = (e) => {

        e.preventDefault();

        var _form = document.getElementById('form-member-edit-data');
        _form.style.top = '-100%';

    }

//#endregion
    
//#region  Delete Member
    const deleteHandle = (id , name) => {

        setID(id);
        setMember(name);

        var _form = document.getElementById('form-member-del-data');
        _form.style.top = '0';
    }

    const SendDelMember = (e) => {

        e.preventDefault();
        _.db_member.doc(id).delete();

        var _form = document.getElementById('form-member-del-data');
        _form.style.top = '-100%';
    }

    const CloseDelMember = (e) => {

        e.preventDefault();

        var _form = document.getElementById('form-member-del-data');
        _form.style.top = '-100%';

    }

//#endregion
    
    return(

        <div className='templete-table-code'>
            <div className='header-table'>
                <span className='text-header'><i className="fas fa-align-justify"></i> รายการผู้ใช้งาน </span>
                <button className='btn btncreate btn-ef' onClick={addHandle}>สร้างผู้ใช้งาน</button>
            </div>

            <div className="table-data">
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650}} aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell>วันที่</TableCell>
                            <TableCell align="left">ไอดี</TableCell>
                            <TableCell align="left">หมายเหตุ</TableCell>
                            <TableCell align="left">จัดการ</TableCell>
                            
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {itemMember.map((row) => (
                            <TableRow key={`${row.data().name}-${0}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">{row.data().objcreate}</TableCell>
                            <TableCell align="left">{row.data().name}</TableCell>
                            <TableCell align="left">{row.data().description}</TableCell>
                            <TableCell align="left">
                                <IconButton aria-label="delete" onClick={() => editHandle(row.id , row.data())}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton aria-label="delete" onClick={() => deleteHandle(row.id , row.data().name)}>
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{display:"flex" , alignItems:"center" , width:"auto" , justifyContent:"center" , background:"aliceblue"}}><Button onClick={fetchMore}>โหลดเพิ่ม </Button></div>
            </div>
            <AddMember/>
            <form id={'form-edit'}>

                <div className='form-data' id='form-member-edit-data'>

                    <div className='box-data'>
                        <div className='text-white'>
                            <h1 >แก้ไขข้อมูลผู้ใช้งาน</h1>
                        </div>
                        <div className='form-input'>
                            <input className='form-control' id={'_member'} type='text' value={member} onChange={(e) => setMember(e.target.value)} placeholder='รหัสไอดี'></input>
                        
                        </div>
                        <div className='form-input'>
                            <input className='form-control' id={'_description'} type='text' value={description} onChange={(e) => setDescription(e.target.value)} placeholder='รายละเอียด'></input>
                        
                        </div>
                        <div className='form-input-btn'>

                                <div className='form-btn'>
                                    <button className='btn btncreate btn-ef' onClick={SendEditMember}>ยืนยัน</button>
                                    <button className='btn btncancel btn-ef'onClick={CloseEditMember}>ยกเลิก</button>
                                </div>                   
                        </div>
                    </div>
                </div>
            </form>
            <form id="form-del">
                <div className='form-data' id='form-member-del-data'>
                    <div className='box-data-small'>

                        <div className='text-white'>
                            <h1 >ลบรหัสโค้ด</h1>
                        </div>

                        <div className='form-input'>
                            <span>ไอดีผู้ใช้งาน</span>
                            <input className='form-control' id={'_delcode'} type='text' value={member} readOnly></input>
                        </div>

                        <div className='form-input-btn'>
                            <div className='form-btn'>
                                <button className='btn btncreate btn-ef' type='submit' onClick={SendDelMember}>ยืนยัน</button>
                                <button className='btn btncancel btn-ef' onClick={CloseDelMember}>ยกเลิก</button>
                            </div>                   
                        </div> 

                    </div>
                </div>
            </form>
        </div>

    )

}

export default MamberPage