import React, { useEffect, useState } from 'react';
import _ from "../../../utils/firebase";
import '../../../public/stylesheets/style-confirm.css';
import ReactDOM from 'react-dom';
import PhetnakaCode from '../../../pages/codePhetnakaPage';
import _PCode from '../dto/codeModel';
import SideBarItem from '../../sidebarItem';
import ItemPrefix, { getPrefixData } from '../../common/prefix';

function CreateCodePhetnaka () {

    const [_userID , setUserID] = useState('user01')
    const [_prefix , setPrefix] = useState();
    const [_codeType , setCodeType] = useState();
    const [_amount , setAmount] = useState(1);
    const [_description , setDescription] = useState('');
    const [_prefixArray , setPrefixArray] = useState([]);


    const RandomGenCode = (max , min) => {
        return Math.floor(Math.random() * (max - min + 1) + min)
    }

    const MemberPrefix = () => getPrefixData().then((doc) => {
        setPrefixArray(doc)
    })

    useEffect(() => {

        MemberPrefix()
        

        return () => {
            MemberPrefix()
        }
    } , [])

    const AddNewCode = (_code) => {

        const jsonPrefix = JSON.parse(_prefix);
        const prefixModel = new _PCode.P_PrefixModel();
        prefixModel.prefix = jsonPrefix;

        const model = new _PCode.P_CodeModel();
        model.userID = `${prefixModel.prefix.name}${_userID}`;
        model.codeNumber = `${prefixModel.prefix.code}${_code}`;
        model.codeType = _codeType;
        model.timestamp = _.db_time;
        model.amount = _amount;
        model.status = true;
        model.description = _description;

        _.p_db_code.doc(model.codeNumber).set({...model});
        _.p_db_logCode.add({...model});

    }

    const formData = async (e) => {

        e.preventDefault();
    
        for(var i=0; i< 1; i++) {

            var gen = RandomGenCode(0 , 99999);
            let compare = null;
            if(gen >= 0 && gen <= 9) {
                compare = `0000${gen}`;
            }else if(gen >= 10 && gen <= 99 ) {
                compare = `000${gen}`;
            }else if(gen >= 100 && gen <= 999) {
                compare = `00${gen}`;
            }else if(gen >= 1000 && gen <= 9999) {
                compare = `0${gen}`;
            }else if(gen >= 10000 && gen <= 99999) {
                compare = gen;
            }

            AddNewCode(compare);
        }

        CloseFormCreateData();

    }

    const CloseFormCreateDef = (e) => {

        e.preventDefault();

        var _form = document.getElementById('formCreateDataPhetnaka');
        _form.style.top = '-100%';

    }

    const CloseFormCreateData = () => {

        var _form = document.getElementById('formCreateDataPhetnaka');
        _form.style.top = '-100%';


        refreshPage();

    }

    const refreshPage = () => {

        setTimeout(() => {
            
            ReactDOM.render(<SideBarItem pages={''}/> , document.getElementById('root'));
            ReactDOM.render(<SideBarItem pages={<PhetnakaCode/>}/> , document.getElementById('root'));
            
        },500);

    }

    return(

        <form>
            <div className='formCreateData' id='formCreateDataPhetnaka'>
                <div className='box-data'>
                    <div className='text-white'>
                        <h1 >สร้างรหัสโค้ดเพชรนาคา</h1>
                    </div>
                    
                    <div className='form-input'>
                        <span>คำนำหน้า</span>
                        <select className='form-control' defaultValue={'DEFAULT'} value={_prefix} onChange={(e) => setPrefix(e.target.value)} required>
                            <option value='DEFAULT' disabled>เลือกรายการ</option>
                            {_prefixArray.map((doc) => {
                                return <ItemPrefix doc={doc}/>
                            })}
                        </select>
                    </div>
                    <div className='form-input'>
                        <span>ผู้ใช้งาน</span>
                        <input className='form-control' id={'_userID'} type='number' value={_userID} onChange={(e) => setUserID(e.target.value)} placeholder='ตัวอย่าง 0000001'></input>
                    
                    </div>
                    <div className='form-input'>
                        <span>ระดับโค้ด</span>
                        <select className='form-control' defaultValue={'DEFAULT'} value={_codeType} onChange={(e) => setCodeType(e.target.value)} required>
                            <option value='DEFAULT' disabled>เลือกรายการ</option>
                            <option value='silver'>ซิลเวอร์</option>
                            <option value='gold'>โกลด์</option>
                            <option value='platinum'>เเพลตินั่ม</option>
                            <option value='diamond'>ไดม่อน</option>
                            <option value='silver_Demo'>ซิลเวอร์ Demo</option>
                            <option value='gold_Demo'>โกลด์ Demo</option>
                            <option value='platinum_Demo'>เเพลตินั่ม Demo</option>
                            <option value='diamond_Demo'>ไดม่อน Demo</option>
                        </select>
                    </div>
                    <div className='form-input'>
                        <span>จำนวนสิทธิ์</span>
                        <input className='form-control' id={'_amount'} type='number' value={_amount} onChange={(e) => setAmount(e.target.value)} placeholder='จำนวน' required></input>
                    </div>
                    <div className='form-input'>
                        <span>หมายเหตุ</span>
                        <input className='form-control' id={'_description'} type='text' value={_description} onChange={(e) => setDescription(e.target.value)}  placeholder='หมายเหตุ'></input>
                    </div>
                    <div className='form-input-btn'>

                            <div className='form-btn'>
                                <button className='btn btncreate' type='submit' onClick={(e) => formData(e)}  >ยืนยัน</button>
                                <button className='btn btncancel' onClick={CloseFormCreateDef}>ยกเลิก</button>
                            </div>                   
                    </div>
                </div>
            </div>
        </form>


    )

}


export default CreateCodePhetnaka;