import { useEffect, useState } from "react"
import _ from "../../utils/firebase"


export const getPrefixData = async () => {

    try {
        const snapshot = await _.db_prefix.get();
        let items = [];

        snapshot.docs.forEach((doc) => {
            const data = doc.data()
            items.push(data)
        })
        return items

    } catch(err) {
        console.log(err.message);
    }
} 


const ItemPrefix = (props) => {
    return (
        <>
            <option value={`${JSON.stringify(props.doc.prefix)}`}>{props.doc.prefix.name}</option>
        </>
    )

}

export default ItemPrefix
