import React, { useEffect, useState } from 'react';
import _ from "../../../utils/firebase";
import '../../../public/stylesheets/style-confirm.css';
import ReactDOM from 'react-dom';
import _Stone from '../dto/stoneModel';
import SideBarItem from '../../sidebarItem';
import SettingPhetnakaPage from '../../../pages/settingPhetnakaPage';


function EditCodeStone () {
    
    const [_stoneID , setStoneID] = useState();
    const [_status , setStatus] = useState();

    const UpdateCompany = async (e) => {

        e.preventDefault();

        const _stoneID = _Stone.StoneModel.prototype.key;
       
        let _setStatus = false;

        if(_status === undefined) {

            _setStatus = _Stone.StoneModel.prototype.status;

        } else {

            _setStatus = _status;
            _setStatus === 'true' ? _setStatus = true : _setStatus = false;

        }

        await _.p_db_reward.doc(_stoneID).update({
            'status._normal':_setStatus
        });

        CloseFormEditDataToRelode();
    }

    const CloseFormEditDataToRelode = () => {

        var _form = document.getElementById('formEditDataStone');
        _form.style.top = '-100%';

        refreshPage();

    }

    const CloseFormEditData = () => {

        var _form = document.getElementById('formEditDataStone');
        _form.style.top = '-100%';

    }

    const refreshPage = () => {

        setTimeout(() => {

            ReactDOM.render(<SideBarItem pages={''}/> , document.getElementById('root'));
            ReactDOM.render(<SideBarItem pages={<SettingPhetnakaPage/>}/> , document.getElementById('root'));
            
        },500);

    }
    
    return(

        <div className='formEditData' id='formEditDataStone'>
            <div className='box-data'>

                <div className='text-white'>
                    <h1 >แก้ไขข้อมูลหินนาคา</h1>
                </div>

                 <div className='form-input'>
                    <span>สถานะ</span>
                    <select className='form-control'  id={'_edstatus-c'} value={_status}  onChange={(e) => setStatus(e.target.value)} >
                        <option value={'true'} >เปิดใช้งาน</option>
                        <option value={'false'}>ปิดใช้งาน</option>
                    </select>
                   
                </div>
               
                <div className='form-input-btn'>
                    <div className='form-btn'>
                        <button className='btn btncreate' type='submit' onClick={UpdateCompany}>ยืนยัน</button>
                        <button className='btn btncancel' onClick={CloseFormEditData}>ยกเลิก</button>
                    </div>                   
                </div> 

            </div>
        </div>
    )

}


export default EditCodeStone;