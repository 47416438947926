import React, { useEffect, useState } from "react";
import _ from "../../../utils/firebase";

const AddMember = () => {

    const [member , setMember] = useState('');
    const [description , setDescription] = useState('');

    const SendAddMember = (e) => {

        e.preventDefault();
        let today = new Date(),
        h = today.getHours(),
        m = today.getMinutes();

        if(h < 10) h = '0' + h;
        if(m < 10) m = '0' + m;

        const time = h +':'+ m;
        const date = new Date().toISOString().slice(0,10);

        const mydate = date + " : " + time;
        const objcreate = mydate;

        _.db_member.add({
            name:member,
            description:description,
            option:{firebaseTimeStamp:_.db_time},
            objcreate:objcreate
        });

        var _form = document.getElementById('form-member-create-data');
        _form.style.top = '-100%';
    }

    const CloseAddMember = (e) => {

        e.preventDefault();

        var _form = document.getElementById('form-member-create-data');
        _form.style.top = '-100%';

    }

    return(

        <form>

            <div className='form-data' id='form-member-create-data'>

                <div className='box-data'>
                    <div className='text-white'>
                        <h1 >สร้างผู้ใช้งาน</h1>
                    </div>
                    <div className='form-input'>
                        <input className='form-control' id={'_member'} type='text' value={member} onChange={(e) => setMember(e.target.value)} placeholder='รหัสไอดี' required></input>
                    
                    </div>
                    <div className='form-input'>
                        <input className='form-control' id={'_description'} type='text' value={description} onChange={(e) => setDescription(e.target.value)} placeholder='รายละเอียด'></input>
                    
                    </div>
                    <div className='form-input-btn'>
                        <div className='form-btn'>
                            <button className='btn btncreate btn-ef' onClick={SendAddMember}>ยืนยัน</button>
                            <button className='btn btncancel btn-ef' onClick={CloseAddMember}>ยกเลิก</button>
                        </div>                   
                    </div>
                </div>
            </div>
        </form>
    )
}

export default  AddMember;