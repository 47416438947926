import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/auth';

/// Lotto lucky
const firebaseConfig = {
    apiKey: "AIzaSyB_zQeciCbW0fhTjkAsnlunnKHnt3rTchc",
    authDomain: "lottolucky-cloud.firebaseapp.com",
    projectId: "lottolucky-cloud",
    storageBucket: "lottolucky-cloud.appspot.com",
    messagingSenderId: "384730584818",
    appId: "1:384730584818:web:1c9864aee9037a9007e26b",
    measurementId: "G-PKFYCSQHZ1"
}


const app = firebase.initializeApp(firebaseConfig)

const db_prefix = app.firestore().collection('t_prefix'); /// prefix
const db_rate_prefix = app.firestore().collection('t_stone'); /// prefix rate

const db_history = app.firestore().collection('h_draco'); /// history draco
const db_member = app.firestore().collection('t_users'); /// all users 
const db_code = app.firestore().collection('t_code_draco'); /// collection code in draco
const db_rules = app.firestore().collection('rule'); /// rule in game
const db_reward = app.firestore().collection('r_draco'); /// rewards draco
const db_eggs = app.firestore().collection('s_draco'); /// setup draco game 
const db_logCodeDraco = app.firestore().collection('log_code_draco'); /// history all log code to generate in draco
const db_logRewardDraco = app.firestore().collection('log_r_draco'); /// history all log rewards opened 

const db_storage = app.storage();
const db_time = firebase.firestore.FieldValue.serverTimestamp();

const db_wheel = app.firestore().collection('s_wheel'); /// setup wheel game
const db_codeWheel = app.firestore().collection('t_code_wheel'); /// collection code in wheel
const db_userHistoryWheel = app.firestore().collection('h_wheel'); /// history wheel
const db_logCodeWheel = app.firestore().collection('log_code_wheel'); /// history all log code to generate in wheel
const db_logRewardWheel = app.firestore().collection('log_r_wheel'); /// history all log rewards opened

const p_db_app = app.firestore().collection('d_app'); /// app status
const p_db_code = app.firestore().collection('t_code_phetnaka'); /// code phetnaka
const p_db_reward = app.firestore().collection('s_phetnaka'); /// set reward to stone
const p_db_history = app.firestore().collection('h_phetnaka'); /// user history opened reward
const p_db_logCode = app.firestore().collection('log_code_phetnaka'); /// report generation code 



const auth = app.auth();


const _ ={

    auth,
    db_prefix,
    db_rate_prefix,
    db_history,
    db_member,
    db_code,
    db_rules,
    db_reward,
    db_eggs,
    db_time,
    db_storage,
    db_codeWheel,
    db_userHistoryWheel,
    db_wheel,
    db_logCodeWheel,
    db_logCodeDraco,
    db_logRewardDraco,
    db_logRewardWheel,
    p_db_app,
    p_db_code,
    p_db_reward,
    p_db_history,
    p_db_logCode
}

export default _