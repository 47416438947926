
class P_CodeModel {
    
    // id = null
    userID = null
    codeNumber = null
    codeType = null
    amount = 0
    status = false
    description = null
    timestamp = null

}

class P_PrefixModel {
    prefix = {
        name:null,
        code:null,
    };
}

const _PCode = {

    P_CodeModel,
    P_PrefixModel

}

export default _PCode;
