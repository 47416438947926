import ReactDom from 'react-dom';
import { useState } from 'react';
import MemberPage from '../pages/memberPage';
import _ from '../utils/firebase';
import '../public/stylesheets/style-sidebar.css';
import CodePage from '../pages/codePage';
import RulesPage from '../pages/rules.Page';
import HistoryPage from '../pages/historyPage';
import EggsPage from '../pages/eggsPage';
import logo_game1 from '../images/game_1.png'
import logo_game2 from '../images/game_2.png'
import CodeWheelPage from '../pages/codeWheel';
import WheelPage from '../pages/wheelPage';
import WheelHistoryPage from '../pages/wheelHistory';
import CodePhetnakaPage from '../pages/codePhetnakaPage';
import SettingPhetnakaPage from '../pages/settingPhetnakaPage';

const SideBarItem = (props) => {

    const RenderMember = (e) => {
        e.preventDefault();
        ReactDom.render(<SideBarItem pages={<MemberPage/>}/> , document.getElementById('root'));
    }

    const RenderCode = (e) => {
        e.preventDefault();
        ReactDom.render(<SideBarItem pages={<CodePage/>}/> , document.getElementById('root'));
    }

    const RenderCodeWheel = (e) => {
        e.preventDefault();
        ReactDom.render(<SideBarItem pages={<CodeWheelPage/>}/> , document.getElementById('root'));
    }

    const RenderCodePhetnaka = (e) => {
        e.preventDefault();
        ReactDom.render(<SideBarItem pages={<CodePhetnakaPage/>}/> , document.getElementById('root'));
    }

    const RenderHistory = (e) => {
        e.preventDefault();
        ReactDom.render(<SideBarItem pages={<HistoryPage/>}/> , document.getElementById('root'));
    }
    const RenderWheelHistory = (e) => {
        e.preventDefault();
        ReactDom.render(<SideBarItem pages={<WheelHistoryPage/>}/> , document.getElementById('root'));
    }

    const RenderEggs = (e) => {
        e.preventDefault();
        ReactDom.render(<SideBarItem pages={<EggsPage/>}/> , document.getElementById('root'));
    }
    const RenderWheel = (e) => {
        e.preventDefault();
        ReactDom.render(<SideBarItem pages={<WheelPage/>}/> , document.getElementById('root'));
    }

    const RenderSettingPhetnaka = (e) => {
        e.preventDefault();
        ReactDom.render(<SideBarItem pages={<SettingPhetnakaPage/>}/> , document.getElementById('root'));
    }

    const RenderRules = (e) => {
        e.preventDefault();
        ReactDom.render(<SideBarItem pages={<RulesPage/>}/> , document.getElementById('root'));
    }

    const RenderSignOut = (e) => {
        e.preventDefault();

        _.auth.signOut();

        setTimeout(() => {

            window.location.reload();
            
        },500);
    }

    return(

        <div className='wrapper'>
            <div className="sidebar">
                <h2> Lotto Lucky</h2>
                <ul>
                    <li><a href={'/'} onClick={RenderMember}><i className="fas fa-key"></i>ผู้ใช้งาน</a></li>
                    {/* <li><a href={'/'} onClick={RenderCode}><i className="fas fa-key"></i>รหัสโค้ดล่าไข่มังกร</a></li>
                    <li><a href={'/'} onClick={RenderCodeWheel}><i className="fas fa-key"></i>รหัสโค้ดวงล้อ</a></li> */}
                    <li><a href={'/'} onClick={RenderCodePhetnaka}><i className="fas fa-key"></i>รหัสเพชรนาคา</a></li>
                    {/* <li><a href={'/'} onClick={RenderHistory}><i className="fas fa-key"></i>ประวัติรางวัลล่าไข่มังกร</a></li>
                    <li><a href={'/'} onClick={RenderWheelHistory}><i className="fas fa-key"></i>ประวัติรางวัลวงล้อ</a></li>
                    <li><a href={'/'} onClick={RenderEggs}><i className="fas fa-key"></i>กิจกรรมไข่มังกร</a></li>
                    <li><a href={'/'} onClick={RenderWheel}><i className="fas fa-key"></i>กิจกรรมวงล้อ</a></li> */}
                    <li><a href={'/'} onClick={RenderSettingPhetnaka}><i className="fas fa-key"></i>กิจกรรมเพชรนาคา</a></li>
                    {/* <li><a href={'/'} onClick={RenderRules}><i className="fas fa-key"></i>กติกา</a></li> */}
                    <li><a href={'/'} onClick={RenderSignOut}><i className="fas fa-sign-out-alt"></i>ออกจากระบบ</a></li>
                </ul>
                <div className="social_app">
                    <a href="https://lottolucky-dragon-cloud.web.app/"><img src={logo_game1} alt="ล่าไข่มังกร"/></a>
                    <a href="https://lottolucky-wheellucky-cloud.web.app/"><img src={logo_game2} alt="วงล้อมังกร" /></a>
                </div>
            </div>
            <div className="main_content">
                <div className="col-dataSerial">
                    <div className="container-serial">
                        <div className="row-serial">
                            <div className="col-serial">
                                <div className="list-card"> 
                                    <div className="card-body">
                                            {props.pages}
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>           
                </div>
            </div>
        </div>

    )

}

export default SideBarItem